<template>
  <section>
    <top-alternative-section :image="PlanificacionImage" :title="$t('simulacion-y-planificacion.topAlternative.title')"
      :content="$t('simulacion-y-planificacion.topAlternative.content')"></top-alternative-section>

    <features-section :title="$t('simulacion-y-planificacion.features.title')" :description="$t('simulacion-y-planificacion.features.description')"
      :items="$t('simulacion-y-planificacion.features.items')"></features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import PlanificacionImage from "../../assets/planificacion.jpg";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";

export default {
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      PlanificacionImage: PlanificacionImage,
      
    };
  }, 
  metaInfo: {
    title: 'Simulación y planificación',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Analiza los procesos actuales (mejora y optimización) y procesos futuros (anticipación de soluciones) con el fin de obtener el diseño más eficiente con diferentes objetivos.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
};
</script>
